<template>
<app-menu key="user-menu" :items="items">
    <template #activator="{ attrs, on }">
        <v-btn class="text--secondary px-0 px-md-2 btn-menu" text v-bind="attrs" v-on="on">
            <v-icon>fas fa-user-circle</v-icon>

            <app-chevron-down />
        </v-btn>
    </template>

</app-menu>
</template>

<script>
import jwt from "jsonwebtoken"
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    name: 'UserMenu',
    data: () => ({
        timer: null
    }),
    components: {
        AppBtn: () => import('@/components/core/app/Btn'),
        AppMenu: () => import('@/components/core/app/Menu'),
        AppChevronDown: () => import('@/components/core/app/ChevronDown'),
    },
    created: function () {
        this.verifyToken()
        this.timer = setInterval(this.verifyToken, 1000 * 45);
    },
    methods: {
        ...mapActions(["signOut"]),
        async onSignOut() {
            await this.signOut();
            this.$router.push({
                path: "/login"
            }).catch(()=>{});
        },
        verifyToken() {
            const token = jwt.decode(localStorage.getItem("accessToken"))
            if (token != null) {
                if (this.showExpirationWarning && (token.exp - parseInt(Date.now() / 1000)) < 60 * 2) {
                    this.$toast('Session will expire soon', {
                        timeout: 1000 * 100,
                        color: "orange"
                    })
                    this.showExpirationWarning = false
                }
            }
            if (token == null || token.exp < new Date().getTime() / 1000) {
                console.log("EXPIRED");
                this.onSignOut();
            }
        }
    },
    computed: {
        ...mapGetters(["isAuth", "user"]),
        items() {
            return [
                {
                    title: 'Sign out/Cerrar sesion',
                    action: this.onSignOut
                }
            ]
        },
    },
}
</script>
